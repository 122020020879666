import Home from '@/views/Home'
import Search from '@/views/Search'
import Register from '@/views/Register'
import Login from '@/views/Login'
import Detail from '@/views/Detail'
import Content from '@/views/Content'
import Layout from '@/views/Account/layout'
import NotFound from '@/views/NotFound'
// 所有静态路由配置
export default [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/',
  },
  {
    path: '/search/:keyword?',
    name: 'search',
    component: Search
  },
  {
    path: '/detail/:id?',
    name: 'detail',
    component: Detail,
  },
  {
    path: '/standard',
    name: 'Standard',
    component: () => import('@/views/Standard/index.vue'),
  },
  {
    path: '/register',
    component: Register,
    meta: {
      isHideFooter: true
    }
  },
  {
    path: '/privacy',
    component: () => import('@/views/Register/privacy.vue'),
    meta: {
      isHideFooter: true
    }
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      isHideFooter: true
    }
  },
  // 忘记密码
  {
    path: '/reset/password',
    name: "reset_password",
    component: () => import('@/views/resetPassword/index.vue'),
    meta: {
      isHideFooter: true
    }
  },
  {
    path: '/contact',
    name: 'content',
    component: Content,
  },
  {
    path: '/account',
    name: 'account',
    component: Layout,
    redirect: '/account/index',
    children: [
      {
        path: 'index',
        name: "index",
        component: () => import('@/views/Account/index.vue'),
      },
      {
        path: 'order',
        name: "order",
        component: () => import('@/views/Account/order'),
      },
      {
        path: 'address',
        name: "address",
        component: () => import('@/views/Account/address'),
      },
      {
        path: 'favorite',
        name: "favorite",
        component: () => import('@/views/Account/favorite'),
      },
      {
        path: 'coupon',
        name: "coupon",
        component: () => import('@/views/Account/coupon'),
      },
      {
        path: 'contribute',
        name: "contribute",
        component: () => import('@/views/Account/contribute'),
      },
      {
        path: 'balance',
        name: "balance",
        component: () => import('@/views/Account/balance'),
      },
      {
        path: 'recharge',
        name: "recharge",
        component: () => import('@/views/Account/balance/recharge.vue'),
      },
      {
        path: 'recharge/add',
        name: "rechargeAdd",
        component: () => import('@/views/Account/balance/RechargeAdd.vue'),
      },
      {
        path: 'setting',
        name: "setting",
        component: () => import('@/views/Account/setting'),
      },
      {
        path: 'password',
        name: "password",
        component: () => import('@/views/Account/setting/password.vue'),
      },

    ]
  },
  {
    path: '/404',
    component: NotFound,
    meta: {
      isHideFooter: true
    }
  }, // 添加404页面的路由配置
  { path: '*', redirect: '/404' },

]
