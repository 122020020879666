<template>
  <div class="footer">
    <div class="wrapper">
      <div class="link-box">
        <div class="list-box">
          <div class="title">联系方式</div>
          <div class="list">
            <span>服务热线：17732384333</span>
            <span>电子邮箱：1258237852@qq.com </span>
            <span>公司地址：北京市怀柔区桥梓镇兴桥大街1号南楼203室</span>
          </div>
        </div>
        <div class="list-box">
          <div class="code">
            <img src="@/assets/wxcode.jpg" />
            <div class="text">
              <span class="icon iconfont icon-weixin"></span>
              官方微信号
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <span
          >版权所有 © 2016-2023 北京中鸿卓尚科技有限公司 All Rights
          Reserved.</span
        >
        <a href="https://beian.miit.gov.cn">京ICP备2023021777号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
/*页面底部.开始*/
.wrapper {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: 50px;
  background: #282828;
}
.footer .delivery-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #3a3030;
}
.footer .delivery-box li {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.footer .delivery-box li .icon {
  margin-right: 14px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  font-size: 23px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 36px;
}
.footer .delivery-box li .text {
  color: #fff;
  font-size: 14px;
}
.footer .link-box {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.footer .link-box .list-box {
  margin: 0 30px;
}
.footer .link-box .list-box .title {
  margin-bottom: 15px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.footer .link-box .list-box .list {
  display: flex;
  flex-direction: column;
}
.footer .link-box .list-box .list a,
.footer .link-box .list-box .list span {
  display: block;
  color: #ccc;
  line-height: 30px;
}
.footer .link-box .list-box .code img {
  width: 98px;
  height: 98px;
  vertical-align: middle;
}
.footer .link-box .list-box .code .text {
  margin-top: 10px;
  color: #999;
}
.footer .link-box .list-box .code .text .icon {
  margin-right: 2px;
  line-height: 1;
  vertical-align: middle;
}
.footer .copyright {
  padding: 10px 0;
  border-top: 1px solid #3a3030;
  color: #ccc;
  font-size: 12px;
  line-height: 24px;
}

/*页面底部.结束*/
</style>