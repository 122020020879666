<template>
  <div class="header-top">
    <div class="wrapper">
      <div class="left-box" v-if="token!=''">
        <router-link to="/"
          ><span class="icon iconfont icon-home"></span>首页</router-link
        >
        <span>欢迎光临：{{ account.userName }}</span>
        <a @click="logout">退出</a>
      </div>
      <div class="left-box" v-else>
        <router-link to="/"
          ><span class="icon iconfont icon-home"></span>首页</router-link
        >
        <router-link to="/login">请登录</router-link>
        <router-link to="/register">免费注册</router-link>
      </div>

      <div class="right-box">
        <router-link to="/standard"
          ><span class="icon iconfont icon-user"></span>会员介绍</router-link
        >
        <router-link to="/account"
          ><span class="icon iconfont icon-user"></span>会员中心</router-link
        >
        <router-link to="/contact">
          <span class="icon iconfont icon-tel"></span>联系我们
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
      name: "",
      token:'',
      account: null,
    };
  },
  mounted() {
    const token = sessionStorage.getItem('dt_client_access_token');
    if (token) {
      this.token = token;
      this.getUserInfo();
    }else{
      this.token = false;
    }
    // 通过全局总线绑定removeKeyword事件监听
    this.$bus.$on("removeKeyword", () => {
      this.keyword = ""; // 置空我们的搜索关键字
    });
  },
  methods: {
    async getUserInfo() {
      let account = await this.$http.get(`/api/account/member/info`);
      this.account = account;
      console.log("account:", account);
    },
    logout() {
      window.sessionStorage.removeItem("dt_client_access_token");
      window.sessionStorage.removeItem("dt_client_refresh_token");
      //删除Cookies
      let expires = new Date();
      expires.setTime(expires.getTime() + -1 * 24 * 60 * 60 * 1000);
      document.cookie =
        "dt_client_access_token='';expires=" + expires + ";path=/";
      //跳转到登录页面
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="less">
.header-top {
  width: 100%;
  height: 40px;
  line-height: 40px;
  min-width: 1200px;
  color: #b4b4b4;
  font-size: 12px;
  background: #282828;
}
.header-top .wrapper {
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top .left-box a,
.header-top .right-box a {
  position: relative;
  color: #b4b4b4;
  margin: 0 8px;
}
.header-top .left-box a:hover,
.header-top .right-box a:hover {
  color: #fff;
}
.header-top .left-box a::before,
.header-top .right-box a::before {
  position: absolute;
  content: " ";
  width: 1px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.11);
  left: -10px;
  top: 50%;
  margin-top: -5px;
}
.header-top .left-box a:first-child::before,
.header-top .right-box a:first-child::before {
  display: none;
}
.header-top .left-box a .icon,
.header-top .right-box a .icon {
  margin-right: 3px;
  font-size: 12px;
}
</style>
