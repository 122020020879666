<template>
  <!--头部搜索框-->
  <div class="wrap head-wrap">
    <a class="top-logo" title="指南针" :href="'/'">
      <img width="195" src="@/assets/logo.jpg" alt="指南针" />
      <h1
        style="
          color: #a26515;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 2.5px;
          margin: 6px 0 0 0;
          text-align: center;
        "
      >
        招投标行业大数据平台
      </h1>
    </a>
    <div class="search-box">
      <div class="top-tel">
        <ul>
          <li :class="{ active: isActive }" @click="handleClick">查项目</li>
          <li :class="{ active: !isActive }" @click="handleClick">查企业</li>
        </ul>
        <!-- <img
          class="tel"
          src="http://www.bidizhaobiao.com/front/img/top_tel.svg"
        /> -->
      </div>
      <div class="search">
        <input
          v-model="keyword"
          name="searchCondition.SearchWord"
          id="SearchInput"
          class="isTab1 search-input"
          type="text"
          autocomplete="off"
          placeholder="请输入关键词，可搜索到您想要的招中标项目信息（例如：电梯）"
        />
        <span class="search-btn pull-right" @click="goToUrl">搜索</span>
      </div>
      <div class="hot-search">
        <img
          class="margin-r5"
          src="http://www.bidizhaobiao.com/front/img/index/svg/fire.svg"
        />
        <span class="rmgjc">热门搜索：</span>
        <div class="hot-search-word">
          <a
            v-for="item in hotwords"
            :key="item"
            @click="handleHot(item)"
            class="hotSearch"
          >
            {{ item }}</a
          >
        </div>
      </div>
    </div>
    <ul class="government-list pull-left">
      <li>
        <a rel="nofollow" target="_blank" href="http://www.ndrc.gov.cn/"
          ><span class="item">中华人民共和国发改委</span>
        </a>
      </li>
      <li>
        <a rel="nofollow" target="_blank" href="http://www.mohurd.gov.cn/"
          ><span class="item">中华人民共和国住建部</span>
        </a>
      </li>
      <li>
        <a rel="nofollow" target="_blank" href="http://www.mof.gov.cn/"
          ><span class="item">中华人民共和国财政部</span>
        </a>
      </li>
      <li>
        <a rel="nofollow" target="_blank" href="http://www.mofcom.gov.cn/"
          ><span class="item">中华人民共和国商务部</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "typeNav",
  data() {
    return {
      keyword: "",
      isActive: true,
      hotwords: ["物业", "装修", "电梯", "绿化", "广告", "消防", "药品"],
    };
  },
  created() {
    this.keyword = this.$route?.params?.keyword;
    this.$store.commit("setKeyword", this.keyword);
  },
  methods: {
    handleHot(keyword) {
      this.$store.commit("setKeyword", keyword);
      this.keyword = keyword;
      this.$router.push({
        name: "search",
        params: {
          keyword: keyword || undefined,
        },
      });
    },
    handleClick() {
      this.isActive = !this.isActive;
    },
    goToUrl() {
      this.$store.commit("setKeyword", this.keyword);
      this.$router.push({
        name: "search",
        params: {
          keyword: this.keyword || undefined,
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.wrap {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 134px;
  .top-logo {
    display: block;
    margin: 33px 53px 89px 0;
    width: 195px;
    height: 40px;
    text-align: center;
  }
  .search-box {
    position: relative;
    width: 650px;
    top: -40px;
    left: -30px;
    .top-tel {
      display: flex;
      margin-top: -10px;
      ul {
        display: flex;
        position: absolute;
        left: 0;
        li {
          display: inline-block;
          margin-right: 16px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          cursor: pointer;
          font-size: 14px;
          position: relative;
          border-radius: 2px;
          &:last-child {
            padding-left: 16px;
          }
          &.active {
            background: #3083eb;
            background-size: 120% 30px;
            color: #fff;
            padding: 0 12px;
          }
        }
      }
      .tel {
        position: absolute;
        right: 0;
      }
    }
    .search {
      position: absolute;
      top: 24px;
      width: 100%;
      .search-btn {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 0;
        width: 100px;
        height: 38px;
        float: left;
        background-color: #3083eb;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        line-height: 36px;
        text-align: center;
        top: 0;
      }
      input {
        width: 550px;
        height: 38px;
        line-height: 20px;
        font-size: 14px;
        padding: 6px 20px 9px 38px;
        margin: 0;
        color: #333;
        box-sizing: border-box;
        border: 2px solid #3083eb;
        outline: none;
      }
    }
    .hot-search {
      position: absolute;
      top: 75px;
      display: flex;
      align-items: center;
      font-size: 13px;
      .margin-r5 {
        margin-right: 5px;
      }
    }
  }
  .government-list {
    margin: 15px 0 0 !important;
    .item {
      display: block;
      padding-left: 20px;
      background: url(../../assets/icon/guohui.svg) center left no-repeat;
      background-size: 14px 16px;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
}
</style>