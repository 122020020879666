/* Vuex最核心的管理对象store */
import Vue from 'vue'
import Vuex from 'vuex'
// 声明使用vuex插件
Vue.use(Vuex)
// 向外暴露store对象
const store = new Vuex.Store({
  state: {
    // 保存全局数据
    keyword: '',
  },
  mutations: {
    setKeyword(state, keyword) {
      state.keyword = keyword
    }
  },
  actions: {

  },
  modules: {

  }
})
export default store