/** 
 * 对axios进行二次封装
 * 1、配置通用的基础路径和超时
 * 2、显示请求进度条
 * 3、成功返回的数据不是response，而直接是响应体数据response.data
 * 4、统一处理请求错误，具体也可以选择处理或者不处理
 * **/
import axios from "axios";
import { refreshToken } from '@/utils/saveToken'
import nProgress from "nprogress";
import 'nprogress/nprogress.css'
// 配置不显示右上角的旋转进度条, 只显示水平进度条
nProgress.configure({ showSpinner: false })

const service = axios.create({
  baseURL: '/api/client', //基础路径
  timeout: 15000 //请求超时时间
})

//添加请求拦截器
service.interceptors.request.use(
  config => {
    // 显示请求中的水平进度条
    nProgress.start()
    //获取token
    const token = window.sessionStorage.getItem('dt_client_access_token');
    if (token) {
      //判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//添加响应拦截器
service.interceptors.response.use(
  response => {
    // 隐藏进度条
    nProgress.done()
    if (response.status) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response.data);
    }
  },
  error => {
    // 隐藏进度条
    nProgress.done()
    if (error.response && error.response.status === 401 && error.response.headers["token-expired"]) {
      if (parent.Vue) {
        return window.parent.refreshToken(service, error);
      } else {
        return refreshToken(service, error);
      }
    }
    return Promise.reject(error);
  }
);

export default service