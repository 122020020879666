<template>
  <div>
    <typeNav />
    <div class="wrap">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <router-link to="/">首页</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link to="/search">招标信息</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link to="/detail">项目详情</router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="content">
        <table>
          <tbody>
            <tr>
              <td class="td-name">项目编号</td>
              <td class="td-content">{{ projectData.projectNO }}</td>
              <td class="td-name">资质要求</td>
              <td class="td-content">
                <span class="opponent-value">建筑工程施工总承包资质三级</span>
                <a
                  target="_blank"
                  href="/client/qualifi_erp.do?qname=建筑工程施工总承包资质三级"
                ></a>
              </td>
            </tr>
            <tr>
              <td class="td-name">招标/采购内容</td>
              <td class="td-content">
                <span class="ellipsisMultiline"> 道路工程</span>
              </td>
              <td class="td-name">预算金额</td>
              <td class="td-content">
                <span class="price-yellow">
                  {{
                    Math.ceil(
                      projectData.projectPrice / 10000
                    ).toLocaleString()
                  }}万元</span
                >
              </td>
            </tr>
            <tr>
              <td class="td-name">获取标书截止时间</td>
              <td class="td-content">
                <span class="opponent-value">2023-08-02</span>
              </td>
              <td class="td-name">投标截止时间</td>
              <td class="td-content">
                <span class="opponent-value">2023-08-04 09:30:00</span>
              </td>
            </tr>
            <tr>
              <td class="td-name">招标单位</td>
              <td class="td-content h20">
                <div>
                  <div
                    class="zbdw tenderee-hover has-export"
                    style="overflow: unset"
                  >
                    <span
                      class="tenderee-value"
                      id="tenderName"
                      style="position: relative; overflow: unset"
                      index="招标单位"
                      title1="广西科技大学"
                    >
                      <a href="/bdqyhx/211659900709720065.html" target="_blank"
                        >广西科技大学</a
                      >
                      <div
                        class="companyInfo-vipPop"
                        id="companyInfo-vipPop"
                        style="display: none"
                      >
                        <div class="pop_loading_mark hide">
                          <img
                            src="/client/details/mainspiltcode/img/loading.gif"
                            style="width: 32px"
                          />
                          <p>数据获取中</p>
                        </div>
                        <p>
                          <span class="title p_info_title">广西科技大学</span>
                          <span class="label regStatus">正常</span>
                        </p>
                        <div class="histroy-num-box clearfix">
                          <div class="item borderR pull-left">
                            <a
                              class="zhaoBiaoNumber_href"
                              href="http://vip.bidizhaobiao.com/admin#?jsonStr=Pnowx579AYSXGgWf0AUT0grRnHrv/rnup14u8Fc2o48Z5wBqqorVb1kjNcKrKyv6dQc/F5XMQUuBHeh60x4T55HzjcE0ArMoOhhClnIGN4g7TaThmOiuBVAG8yBe5IlpZFVi5Iwfa/Bi809BoAeyQ6trMnXt33rA88h4elO5TIg=&amp;tzhrl=zhaobiao&amp;bidiId=211659900709720065&amp;mainEnter=广西科技大学"
                              target="_blank"
                            >
                              <span
                                class="zhaoBiaoNumber-bar"
                                style="width: 100%; display: block"
                              >
                                <span class="num zhaoBiaoNumber">2223</span
                                ><span class="icon"></span>
                              </span>
                            </a>
                            <span
                              class="num-empty_zhaoBiao"
                              style="width: 100%; display: none"
                              >0</span
                            >
                            <p class="vip_label COl333">
                              历史招标 <span class="COl999">（个）</span>
                            </p>
                          </div>
                          <div class="item pull-left">
                            <a
                              class="zhongBiaoNumber_href"
                              href="http://vip.bidizhaobiao.com/admin#?jsonStr=Pnowx579AYSXGgWf0AUT0grRnHrv/rnup14u8Fc2o48Z5wBqqorVb1kjNcKrKyv6dQc/F5XMQUuBHeh60x4T55HzjcE0ArMoOhhClnIGN4g7TaThmOiuBVAG8yBe5IlpZFVi5Iwfa/Bi809BoAeyQ6trMnXt33rA88h4elO5TIg=&amp;tzhrl=zhongbiao&amp;bidiId=211659900709720065&amp;mainEnter=广西科技大学"
                              target="_blank"
                            >
                              <span class="zhongbiaoBiaoNumber-bar">
                                <span class="num zhongbiaoBiaoNumber">18</span>
                                <span class="icon"></span>
                              </span>
                            </a>
                            <span
                              class="num-empty_zhonbiao"
                              style="width: 100%; display: none"
                              >0</span
                            >
                            <p class="vip_label COl333">
                              历史中标 <span class="COl999">（个）</span>
                            </p>
                          </div>
                        </div>
                        <div class="detaile-box">
                          <p class="COl666 fs14 mgb16">
                            法定代表:
                            <span class="COl333 vip_legalPerson">刘宝臣</span>
                          </p>
                          <p class="COl666 fs14 mgb16" id="lxr_item">
                            企业联系人:
                            <span
                              class="residueClick zhaobiao_seeItime click-see"
                              style="display: none"
                              >点击查看</span
                            >
                            <span
                              class="COl333 vip_lxrPerson"
                              style="display: inline"
                              >邹老师</span
                            >
                            <span
                              class="COl333 vip_phone"
                              style="display: inline"
                              >0772-2685509</span
                            >
                            <span
                              class="COlE50 redSee vip_notSee"
                              onclick="loginNoVipPop()"
                              style="display: none"
                              >点击查看</span
                            >
                          </p>
                          <p class="COl666 fs14 mgb16">
                            企业邮箱:<span class="COl333 vip_email">-</span>
                          </p>
                          <p class="COl666 fs14 mgb16">
                            <span class="pull-left inline-block"
                              >企业地址:</span
                            >
                            <span class="COl333 vip_regLocation vip_address"
                              >柳州市文昌路2号</span
                            >
                          </p>
                        </div>
                        <div class="handle-groud clearfix">
                          <a
                            class="pull-left monitor defaultBtn"
                            href="javascript:;"
                            id="monitor_tenderee"
                            ><i class="icon_jiance unmonitoring"></i
                            ><span class="monitorTxt">监控</span></a
                          >
                          <a
                            class="pull-left collect defaultBtn vip_collect notCollect"
                            href="javascript:;"
                            onclick='vipCollect("211659900709720065")'
                            style=""
                            ><i class="icon_collect uncollect"></i>收藏</a
                          >
                          <a
                            class="pull-left collect defaultBtn vip_collect isCollect"
                            href="javascript:;"
                            onclick='vipCollect("211659900709720065")'
                            style="display: none"
                            ><i class="icon_collect"></i>已收藏</a
                          >
                          <a
                            class="toNav qyhx-btn pull-right"
                            target="_blank"
                            href="bdqyhx/211659900709720065.html"
                            >查看企业详情</a
                          >
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </td>
              <td class="td-name">招标联系人/电话</td>
              <td class="td-content">
                <div class="tenderee-hover conmod-title-zbdw" iscansen="true">
                  <p class="zblxr_hasMsg">
                    <span class="zblxr_username">邹老师</span>
                    <span class="zblxr_phone">0772-2685509</span>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="td-name">代理机构</td>
              <td class="td-content tenderee-hover h20">
                <div
                  class="zbdw tenderee-hover has-export"
                  style="overflow: unset"
                >
                  <span
                    id="dljg-value"
                    style="position: relative; overflow: unset; width: 60%>"
                    index="代理机构"
                    class="agency-value tenderee-value dljg-value"
                    title1="广西国盛招标有限公司"
                  >
                    <a
                      target="_blank"
                      href="http://vip.bidizhaobiao.com/admin#?jsonStr=coxp3QB0pxiruF54aDw5754aZa+kBANXRe8JWc46FnCi8KFZ9FOUrOx9Ue+EK4FQRaLaIPNkFy4JFoWdoB6fce5qVBjRc3v1sNc2Qropg9dgYooXzIj7itTnSCCrXBLuxFRoLXN9IPpXaoMRr8AfHej6KjZgJGIPv3UAJgxtfMA=&amp;tzhrl=qyqbfx&amp;bidiId=211655286945959936&amp;identity=30"
                      style="color: #3083eb"
                      >广西国盛招标有限公司</a
                    >
                    <div
                      class="companyInfo-vipPop dljg-vipPop"
                      id="dljg-vipPop"
                      style="display: none"
                    >
                      <div class="pop_loading_mark hide">
                        <img
                          src="/client/details/mainspiltcode/img/loading.gif"
                          style="width: 32px"
                        />
                        <p>数据加载中...</p>
                      </div>
                      <p>
                        <span class="title p_info_title">广西科技大学</span>
                        <span class="label regStatus">正常</span>
                      </p>
                      <div class="histroy-num-box clearfix">
                        <div class="item borderR pull-left">
                          <a
                            class="zhaoBiaoNumber_href"
                            href="http://vip.bidizhaobiao.com/admin#?jsonStr=Pnowx579AYSXGgWf0AUT0grRnHrv/rnup14u8Fc2o48Z5wBqqorVb1kjNcKrKyv6dQc/F5XMQUuBHeh60x4T55HzjcE0ArMoOhhClnIGN4g7TaThmOiuBVAG8yBe5IlpZFVi5Iwfa/Bi809BoAeyQ6trMnXt33rA88h4elO5TIg=&amp;tzhrl=zhaobiao&amp;bidiId=211659900709720065&amp;mainEnter=广西科技大学"
                            target="_blank"
                          >
                            <span
                              class="zhaoBiaoNumber-bar"
                              style="width: 100%; display: block"
                            >
                              <span class="num zhaoBiaoNumber">2223</span>
                              <span class="icon"></span>
                            </span>
                          </a>
                          <span
                            class="num-empty_zhaoBiao"
                            style="width: 100%; display: none"
                            >0</span
                          >
                          <p class="vip_label COl333">
                            历史招标 <span class="COl999">（个）</span>
                          </p>
                        </div>
                        <div class="item pull-left">
                          <a
                            class="zhongBiaoNumber_href"
                            href="http://vip.bidizhaobiao.com/admin#?jsonStr=Pnowx579AYSXGgWf0AUT0grRnHrv/rnup14u8Fc2o48Z5wBqqorVb1kjNcKrKyv6dQc/F5XMQUuBHeh60x4T55HzjcE0ArMoOhhClnIGN4g7TaThmOiuBVAG8yBe5IlpZFVi5Iwfa/Bi809BoAeyQ6trMnXt33rA88h4elO5TIg=&amp;tzhrl=zhongbiao&amp;bidiId=211659900709720065&amp;mainEnter=广西科技大学"
                            target="_blank"
                          >
                            <span class="zhongbiaoBiaoNumber-bar">
                              <span class="num zhongbiaoBiaoNumber">18</span>
                              <span class="icon"></span>
                            </span>
                          </a>
                          <span
                            class="num-empty_zhonbiao"
                            style="width: 100%; display: none"
                            >0</span
                          >
                          <p class="vip_label COl333">
                            历史中标 <span class="COl999">（个）</span>
                          </p>
                        </div>
                      </div>
                      <div class="detaile-box">
                        <p class="COl666 fs14 mgb16">
                          法定代表:
                          <span class="COl333 vip_legalPerson">刘宝臣</span>
                        </p>
                        <p class="COl666 fs14 mgb16" id="lxr_item">
                          企业联系人:
                          <span
                            class="COl333 vip_lxrPerson"
                            style="display: inline"
                            >邹老师</span
                          >
                          <span
                            class="residueClick daili_seeItime click-see"
                            style="display: none"
                            >点击查看</span
                          >
                          <span class="COl333 vip_phone" style="display: inline"
                            >0772-2685509</span
                          >
                          <span
                            class="COl3083EB vip_seeMorePhone moreContactBtn inline-block"
                            onclick='moreContactPerson("广西科技大学")'
                            style="display: inline"
                            >代理机构其他联系人&gt;</span
                          >
                          <span
                            class="COlE50 redSee vip_notSee"
                            onclick="loginNoVipPop()"
                            style="display: none"
                            >点击查看</span
                          >
                        </p>
                        <p class="COl666 fs14 mgb16">
                          企业邮箱:
                          <span class="COl333 vip_email">-</span>
                        </p>
                        <p class="COl666 fs14 mgb16">
                          <span class="pull-left inline-block">企业地址:</span>
                          <span class="COl333 vip_regLocation vip_address"
                            >柳州市文昌路2号</span
                          >
                        </p>
                      </div>
                      <div class="handle-groud clearfix">
                        <a
                          class="pull-left monitor defaultBtn jiance"
                          href="javascript:;"
                          id="monitor_proxy"
                        >
                          <i class="icon_jiance unmonitoring"></i
                          ><span class="monitorTxt">监控</span>
                        </a>
                        <a
                          class="pull-left collect defaultBtn vip_collect notCollect"
                          href="javascript:;"
                          onclick='vipCollect("211659900709720065")'
                          style=""
                          ><i class="icon_collect uncollect"></i>收藏</a
                        >
                        <a
                          class="pull-left collect defaultBtn vip_collect isCollect"
                          href="javascript:;"
                          onclick='vipCollect("211659900709720065")'
                          style="display: none"
                          ><i class="icon_collect"></i>已收藏</a
                        >
                      </div>
                    </div></span
                  >
                </div>
              </td>
              <td class="td-name">代理联系人/电话</td>
              <td class="td-content">
                <div class="tenderee-hover conmod-title-zbdw" iscansen="true">
                  <p class="dllxr_hasMsg">
                    <span class="dllxr_username">黄工</span>
                    <span class="dllxr_phone">0772-3720799</span>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="vF_deail_maincontent clearfix">
        <div class="vF_detail_main">
          <div class="vF_detail_content" v-html="container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from "@/api/ajax";
export default {
  name: "Detail",
  data() {
    return {
      id: "",
      table: "",
      container: ``,
      show: false,
      title: "",
      projectData: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleShow() {
      this.show = !this.show;
    },
    putHistory(item) {
      // 添加历史记录
      const url = `/article/history/`;
      ajax.put(url + item.callIndex).then((res) => {
        console.log(res);
      });
    },
    getData() {
      ajax
        .get("/article/show/" + this.$route?.params?.id)
        .then((response) => {
          this.projectData = response.data;
          console.log(response.data);
          this.container = response.data.content
            .replace(/<meta [^>]*>/g, "")
            .replace(/<style.*?\/style>/g, "");
          this.putHistory(response.data);
        })
        .catch((error) => {
          this.$notify({
            title: "警告",
            message: "请登录后查看内容",
            type: "warning",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  margin: 20px auto;
  background: #fff;
  table {
    width: 100%;
  }
  tr {
    color: #333;
    td {
      border: 1px solid #e3edfb;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      height: 25px;
      line-height: 25px;
    }
    .td-name {
      width: 150px;
      background: #f6faff;
      color: #666;
    }
    .td-content {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      .price-yellow {
        color: #f72;
      }
    }
  }
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
  .el-breadcrumb {
    font-size: 16px;
    line-height: 1;
    margin: 10px 0;
  }
  .vF_deail_maincontent {
    width: 100%;
    background: #fff;
    // float: left;
    margin-top: 10px;
    .vF_detail_main {
      width: 98%;
      margin: 20px 0 10px 10px;
      float: left;

      .vF_detail_content {
        width: 820px;
        margin: 20px auto;
        line-height: 32px;
        font-size: 16px;
        color: #383838;
        border-top: 1px solid #ddd;
        padding-top: 22px;
      }
    }
  }
}
</style>