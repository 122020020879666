import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  // if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => { return; })
}
// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


// 声明使用插件
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes //注册所有路由
})
const whiteList = ['/search', '/',  '/login', '/register', '/privacy', '/standard', '/contact', '/reset/password']
router.beforeEach((to, from, next) => {
  if (window.sessionStorage.getItem('dt_client_access_token')) {
    next()
  } else if (whiteList.includes(to.path)) {
    next()
  } else {
    next('/login')
  }
});
export default router