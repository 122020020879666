<template>
  <div id="app">
    <Header v-if="!$route.meta.isHideFooter" />
    <router-view></router-view>
    <Footer v-if="!$route.meta.isHideFooter" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: { Header, Footer },
  created() {},
  methods: {},
};
</script>

<style>
#app {
  background-color: rgb(249, 249, 249);
}
a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}
</style>
