<template>
  <div>
    <div class="warp">
      <div class="ntitle">
        <div class="h3">{{ title }}</div>
        <router-link tag="a" target="_blank" class="more" to="/search">
          <span>更多</span>
          <span class="icon iconfont icon-right-arrow"></span>
        </router-link>
      </div>
      <!-- 招标专区 -->
      <div class="model">
        <el-row :gutter="40">
          <el-col :span="12">
            <div class="title">
              <div>
                <ul class="d-flex">
                  <li v-for="(tag, index) in tags" :key="index">
                    <span
                      @click="handleClick(tag.id, index)"
                      :class="{ cur: index === cur }"
                      >{{ tag.title }}</span
                    >
                  </li>
                </ul>
              </div>
              <div @click="goToUrl">
                更多
                <img :src="require('@/assets/icon/more.png')" alt="" />
              </div>
            </div>
            <div class="maxItem" v-loading="loading1" v-if="itemList.length">
              <div class="content">
                <span @click="goUrl(`/detail/${itemList[0].callIndex}`)">
                  <img
                    :src="require('@/assets/icon/sy_4ggbg.png')"
                    alt="'/.;m,"
                  />
                </span>
                <div class="left10">
                  <p>{{ itemList[0].projectName }}</p>
                  <div>
                    地区：<span class="c66"
                      >{{ itemList[0].areaName }}&nbsp;&nbsp;</span
                    >
                    发布时间：<span class="c66">{{
                      formatDateYear(itemList[0].announcementTime)
                    }}</span>
                  </div>
                  <div>
                    项目金额：<span class="c66"
                      >{{
                        (itemList[0].projectPrice / 10000).toFixed(2)
                      }}万</span
                    >
                  </div>
                </div>
              </div>
              <ul class="bottom">
                <li v-for="(item, index) in itemList" :key="index">
                  <span @click="goUrl(`/detail/${item.callIndex}`)">
                    <i></i>{{ item.projectName }}
                </span>
                  <span class="time">{{
                    formatDate(item.announcementTime)
                  }}</span>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="title">
              <div>
                <ul class="d-flex">
                  <li v-for="(tag, index) in tags2" :key="index">
                    <span
                      @click="handleClick2(tag.id, index)"
                      :class="{ cur: index === cur2 }"
                      >{{ tag.title }}</span
                    >
                  </li>
                </ul>
              </div>
              <div @click="goToUrl">
                更多
                <img :src="require('@/assets/icon/more.png')" alt="" />
              </div>
            </div>
            <div class="maxItem" v-loading="loading2" v-if="valueList.length">
              <div class="content">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="`/detail/${valueList[0].callIndex}`"
                >
                  <img
                    :src="require('@/assets/icon/sy_9nzjxm.png')"
                    alt="'/.;m,"
                  />
                </router-link>
                <div class="left10">
                  <p>{{ valueList[0].projectName }}</p>
                  <div>
                    地区：<span class="c66"
                      >{{ valueList[0].areaName }}&nbsp;&nbsp;</span
                    >
                    发布时间：<span class="c66">{{
                      formatDateYear(valueList[0].announcementTime)
                    }}</span>
                  </div>
                  <div>
                    项目金额：<span class="c66"
                      >{{
                        (valueList[0].projectPrice / 10000).toFixed(2)
                      }}万</span
                    >
                  </div>
                </div>
              </div>

              <ul class="bottom">
                <li v-for="(item, index) in valueList" :key="index">
                  <router-link
                    tag="a"
                    target="_blank"
                    :to="`/detail/${item.callIndex}`"
                    style="width: 100%"
                    ><i></i>{{ item.projectName }}</router-link
                  >
                  <span class="time">{{
                    formatDate(item.announcementTime)
                  }}</span>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from "@/api/ajax";
import dayjs from "dayjs";
export default {
  props: {
    title: {
      default: "招标中心",
    },
    tags: {
      type: Array,
    },
    tags2: {
      type: Array,
    },
  },
  data() {
    return {
      cur: 0,
      cur2: 0,
      itemList: [],
      valueList: [],
      loading1: false,
      loading2: false,
    };
  },
  created() {
    this.handleClick(this.tags[this.cur].id, this.cur);
    this.handleClick2(this.tags2[this.cur2].id, this.cur2);
  },
  methods: {
    goUrl(url) {
      window.open(url, "_blank");
    },
    goToUrl() {
      let routeData = this.$router.resolve("/search");
      window.open(routeData.href, "_blank");
    },
    formatDateYear(time) {
      return dayjs(time).format("YYYY-MM-DD");
    },
    formatDate(time) {
      return dayjs(time).format("MM-DD");
    },
    handleClick(item, index) {
      this.loading1 = true;
      this.cur = index;
      ajax
        .get("/channel/article/view/2/20?CategoryId=" + item)
        .then((response) => {
          this.loading1 = false;
          this.itemList = response.data;
        })
        .catch((error) => {});
    },
    handleClick2(item, index) {
      this.cur2 = index;
      this.loading2 = true;
      ajax
        .get("/channel/article/view/2/10?CategoryId=" + item)
        .then((response) => {
          this.valueList = response.data;
          this.loading2 = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.model {
  .d-flex {
    display: flex;
  }
  padding: 5px 10px;
  background: #fff;
  .title {
    height: 30px;
    padding: 6px 0px;
    line-height: 2.5;
    border-bottom: 2px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    color: #333;
    ul {
      li {
        min-width: 70px;
        padding: 0 5px;
        cursor: pointer;
        position: relative;
        .cur {
          color: #3083eb !important;
          font-weight: 700;
          position: absolute;
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #3083eb;
            top: 37px;
            left: 50%;
            margin-left: -4px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            top: 36px;
            left: 0;
            background-color: #3083eb;
          }
        }
      }
    }
  }

  .maxItem {
    height: 406px;
    overflow: hidden;
    .content {
      color: #414446;
      display: flex;
      height: 86px;
      padding: 10px 0;
      img {
        width: 134px;
        height: 74px;
      }
      .left10 {
        margin-left: 10px;
        height: 74px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #999;
        p {
          color: #000;
          cursor: pointer;
          font-size: 16px;
          &:hover {
            color: #3083eb;
          }
        }
        .c66 {
          color: #666;
        }
      }
    }
    li {
      display: flex;
      justify-content: space-between;
      height: 20px;
      line-height: 20px;
      padding: 5px 0;
      position: relative;

      i {
        position: absolute;
        top: 41%;
        left: 0px;
        width: 4px;
        height: 4px;
        display: block;
        background-color: #ff6400;
      }
      a {
        max-width: 488px;
        overflow: hidden;
        white-space: nowrap;
        height: 20px;
        line-height: 20px;
        color: #414446;
        padding: 0 10px;
        &:hover {
          color: #3083eb;
        }
      }
      .time {
        width: 50px;
      }
    }
  }
}
</style>