import service from "@/utils/request";
import router from "@/router";
//存储Token到本地
// 是否正在刷新的标记
import Vue from 'vue'
var isRefreshing = false;
export const saveToken = (accessToken, refreshToken) => {
  window.sessionStorage.setItem('dt_client_access_token', accessToken);
  window.sessionStorage.setItem('dt_client_refresh_token', refreshToken);
  //同时将AcccessToken存储到Cookies
  let expires = new Date();
  expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
  document.cookie = "dt_client_access_token=" + escape(accessToken) + ";expires=" + expires + ";path=/";
}
//刷新Token请求方法
export function refreshToken(axiosObj, error) {
  return service.post(
    '/api/auth/refresh',
    {},
    axiosObj
  )
  let refreshToken = window.sessionStorage.getItem('dt_client_refresh_token');
  if (!refreshToken) {
    router.push('/login')
  }
  console.log('开始刷新Token...', error);
  const config = error.config;
  if (!isRefreshing && refreshToken) {
    isRefreshing = true;
    let isRefreshed = false;
    return service.post('/api/auth/retoken', { 'refreshToken': refreshToken }).then(res => {
      console.log('刷新成功', config);
      //设置请求头
      config.headers.Authorization = 'Bearer ' + res.data.accessToken;
      //存储Token
      saveToken(res.data.accessToken, res.data.refreshToken);
      //已经刷新了token，将所有队列中的请求进行重试
      requests.forEach(cb => cb(res.data.accessToken))
      //重试完了清空这个队列
      requests = [];
      isRefreshed = true;
      return axiosObj(config);
    }).catch(err => {
      //console.log('出错了：' + isRefreshing);
      //无法刷新Token时重新登录
      if (!isRefreshed) {
        Vue.prototype.$alert('认证失效，请重新登录!', '登录过期', {
          confirmButtonText: '确认',
          callback: action => {
            //window.sessionStorage.removeItem('dt_client_access_token');
            //window.sessionStorage.removeItem('dt_client_refresh_token');
            router.push('/login')
          }
        })
      }
      return Promise.reject(err);
    }).finally(() => {
      isRefreshing = false
    })
  } else {
    //正在刷新token，返回一个未执行resolve的promise
    return new Promise((resolve) => {
      console.log('加入队列...');
      //将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
      requests.push((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        resolve(axiosObj(config));
      })
    })
  }
}
