import Vue from 'vue'
import App from './App.vue'
import router from './router'
import TypeNav from './components/TypeNav'
import Pagination from '@/components/Pagination'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import service from './utils/request'
import store from './store'

console.log('store:', store);
// 引入swiper样式
import "swiper/swiper.min.css"
import "swiper/swiper-bundle.css"
Vue.component('Pagination', Pagination) // 全局使用<Pagination/> <pagination/>
Vue.component(TypeNav.name, TypeNav)
Vue.use(ElementUI);
Vue.prototype.$http = service
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  //全局事件总线$bus配置
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event')) //这里的名称和上面的名称保持一直
    }, 2000)
  },
  router, //注册路由器
  store,  //注册vuex的store对象
}).$mount('#app')
