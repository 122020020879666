<template>
  <div class="main warp">
    <el-row>
      <el-col :span="6" class="back">
        <ul class="vip">
          <li class="title">
            <div>恭喜中标</div>
          </li>
          <li class="vip-top">
            <div class="logo">横越会员</div>
            <div class="right">
              <div>
                <router-link to="/">公司名称</router-link>
              </div>
              <div><router-link to="/">中标金额：220万</router-link></div>
            </div>
          </li>
          <div style="overflow: hidden">
            <div class="contanier">
              <li
                v-for="(item, index) in supplierList"
                :key="index"
                @click="goToUrl(item.callIndex)"
              >
                <img
                  :src="require('@/assets/icon/yc_zhongbiao02.png')"
                  style="transform: scale(0.5)"
                />
                <a>{{ item.supplierName }}</a>
              </li>
            </div>
          </div>
        </ul>
      </el-col>
      <!-- 轮播图开始 -->
      <el-col :span="12" style="padding: 0 10px">
        <el-carousel height="240px">
          <el-carousel-item v-for="item in 5" :key="item.url">
            <img
              height="213"
              width="580"
              :src="require(`@/assets/icon/g-0${item}.jpg`)"
              class="swiper"
            />
          </el-carousel-item>
        </el-carousel>
        <!-- 轮播图结束 -->
        <!-- VIP开始 -->
        <div class="jtdkh">
          <div class="title">
            <span>VIP项目</span>
            <a @click="goToSearch">
              更多
              <img :src="require('@/assets/icon/more.png')" alt="" />
            </a>
          </div>
        </div>
        <!--VIP结束 -->
        <ul class="jt-item">
          <li v-for="item in vipList" :key="item.callIndex">
            <img :src="require('@/assets/icon/sy_qiye.png')" alt="" />
            <div class="center">
              <a @click="goToUrl(item.callIndex)">{{ item.buyerAgent }}</a>
              <span class="sign">{{ item.areaName }}</span>
              <br />
              <span
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100%;
                "
                >发布单位 : {{ item.buyer }} 发布时间 :
                {{ formatDate(item.announcementTime) }}</span
              >
            </div>
            <div>
              <span class="one">{{
                Math.ceil(item.projectPrice / 1000000).toLocaleString()
              }}</span>
              <br />
              <span>招标金额/百万</span>
            </div>
          </li>
        </ul>
      </el-col>
      <el-col :span="6">
        <!-- 未登录 -->
        <div class="loginRegisterBox">
          <div class="top">
            <router-link class="registerBtn" :to="'/register'">
              <div class="zhuce-box zhuce-bg">
                <span class="zhuce-text">立即注册</span>
                <img
                  class="zhuce-icon pull-right"
                  src="http://www.bidizhaobiao.com/front/img/index/zc_jinru01.png"
                  alt=""
                />
              </div>
            </router-link>
            <router-link
              class="margintop10 bulue loginBtn"
              to="/login"
              id="login"
            >
              <div class="zhuce-box denglu-bg">
                <span class="zhuce-text pull-left">用户登录</span>
                <img
                  class="zhuce-icon pull-right"
                  src="http://www.bidizhaobiao.com/front/img/index/zc_jinru01.png"
                  alt=""
                />
              </div>
            </router-link>
          </div>
          <div class="tqList margintop10">
            <div class="C333 tqlist-title">
              <img
                class="tequan-icon tequan-left"
                src="http://www.bidizhaobiao.com/front/img/index/tq_left.png"
                alt=""
              />
              注册即享6大特权
              <img
                class="tequan-icon tequan-right"
                src="http://www.bidizhaobiao.com/front/img/index/tq_right.png"
                alt=""
              />
            </div>
            <ul class="ul clearfix">
              <li class="pull-left marginbtm14">
                <router-link
                  target="_blank"
                  to="http://www.bidizhaobiao.com/cregister.do"
                  ><span class="C3083EB fs14">免费招标检索</span></router-link
                >
              </li>
              <li class="pull-right marginbtm14">
                <router-link
                  target="_blank"
                  to="http://www.bidizhaobiao.com/cregister.do"
                  ><span class="C3083EB fs14">发布招标公告</span></router-link
                >
              </li>
              <li class="pull-left marginbtm14">
                <router-link
                  target="_blank"
                  to="http://www.bidizhaobiao.com/cregister.do"
                  ><span class="C3083EB fs14">跟踪项目进展</span></router-link
                >
              </li>
              <li class="pull-right marginbtm14">
                <router-link
                  target="_blank"
                  to="http://www.bidizhaobiao.com/cregister.do"
                  ><span class="C3083EB fs14">监测竞争对手</span></router-link
                >
              </li>
              <li class="pull-left marginbtm14">
                <router-link
                  target="_blank"
                  to="http://www.bidizhaobiao.com/cregister.do"
                  ><span class="C3083EB fs14">剖析业主动态</span></router-link
                >
              </li>
              <li class="pull-right marginbtm14">
                <router-link
                  target="_blank"
                  to="http://www.bidizhaobiao.com/cregister.do"
                  ><span class="C3083EB fs14">分析市场趋势</span></router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <!-- 未登录 -->
        <ul class="left h562">
          <li class="title">
            <div class="h2">优秀供应商</div>
          </li>
          <div class="contanier">
            <li v-for="item in excellentList" style="padding-left: 10px">
              <a @click="goToUrl(item.callIndex)" style="width: 100%">{{
                item.supplierName
              }}</a>
            </li>
          </div>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ajax from "@/api/ajax";
import dayjs from "dayjs";
export default {
  data() {
    return {
      timer: null,
      token:'',
      advertis: [
        {
          url: require("@/assets/icon/g-01.jpg"),
        },
        {
          url: require("@/assets/icon/g-02.jpg"),
        },
      ],
      excellentList: [], //优秀供应商列表
      supplierList: [], //供应商列表
      vipList: [], //会员列表
    };
  },
  created() {
    const token = sessionStorage.getItem('dt_client_access_token');
    this.token = token;
    this.getData();
    this.getSupplierData();
    this.getVipData();
  },
  methods: {
    formatDate(time) {
      return dayjs(time).format("YYYY-MM-DD");
    },
    goToSearch() {
      let routeData = this.$router.resolve(`/search`);
      window.open(routeData.href, "_blank");
    },
    goToUrl(id) {
      console.log(id);
      // let routeData = this.$router.resolve({
      //   name: "detail",
      //   params: {
      //     id,
      //   },
      // });
      let routeData = this.$router.resolve(`/detail/${id}`);
      window.open(routeData.href, "_blank");
    },
    // 获取VIP列表
    getVipData() {
      ajax
        .get("/article/view/vip/8")
        .then((response) => {
          console.log("this.vipList:", response);
          this.vipList = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 获取供应商列表
    getSupplierData() {
      ajax
        .get("/supplier/view/channel/50")
        .then((response) => {
          console.log("this.supplierList:", response);
          this.supplierList = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 获取优秀供应商列表
    getData() {
      ajax
        .get("/supplier/view/channel/excellent/50")
        .then((response) => {
          console.log("this.excellentList:", response);
          this.excellentList = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    moveUp() {
      const element = document.querySelectorAll(".contanier");
      let topPosition = 0;
      let that = this;
      function moveUp() {
        topPosition -= 1;
        element.forEach((item) => {
          item.style.top = topPosition + "px";
          if (topPosition <= -600) {
            item.style.top = 0;
            clearInterval(that.timer);
            that.moveUp();
          }
        });
      }
      this.timer = setInterval(moveUp, 100);
    },
  },
  mounted() {
    this.moveUp();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.contanier {
  position: relative;
  top: 0px;
  transition: 0.1s all;
  height: 100%;
}
.loginRegisterBox {
  width: 100%;
  overflow: hidden;
  .tqList {
    width: 100%;
    padding: 16px 0 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #333;
    position: relative;
    .tequan-icon {
      position: absolute;
      margin-top: 23px;
      top: 0;
      width: 25px;
      height: 5px;
      &.tequan-left {
        left: 0;
        margin-left: 34px;
      }
      &.tequan-right {
        right: 0;
        margin-right: 34px;
      }
    }
    ul {
      border: none;
      padding: 0 6px;
      position: relative;
      margin-top: 10px;
      li {
        width: 120px;
        overflow: hidden;
        display: inline-block;

        a {
          color: #3083eb !important;
          font-weight: 500;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }

  .zhuce-box {
    background: linear-gradient(
      90deg,
      rgba(255, 119, 34, 1) 0,
      rgba(255, 180, 34, 1) 100%
    );
    width: 100%;
    height: 48px;
    padding: 0 23px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-content: center;
    &.denglu-bg {
      margin-top: 10px;
      background: linear-gradient(
        90deg,
        rgba(10, 167, 116, 1) 0,
        rgba(56, 194, 157, 1) 100%
      );
    }
    img {
      width: 22px;
      height: 22px;
      margin-top: 13px;
    }
  }
  .zhuce-text {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    line-height: 14px;
    margin-top: 16px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 240px;
  margin: 0;
}
.jtdkh {
  width: 100%;
  .title {
    height: 28px;
    border-bottom: 2px solid #3083eb;
    line-height: 28px;
    width: 100%;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    span {
      line-height: 28px;
      text-align: center;
      color: #fff;
      background: #3083eb;
      font-size: 15px;
      font-weight: 700;
      width: 102px;
      border-radius: 4px 4px 0 0;
      -webkit-radius: 4px 4px 0 0;
      -moz-radius: 4px 4px 0 0;
    }
  }
}
.jt-item {
  overflow: hidden;
  padding: 0 14px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  li {
    height: 20% !important;
    padding: 11px 0 !important;
    border-bottom: 1px dashed #e5e5e5 !important;
    align-items: center;
    img {
      width: 38px;
      height: 38px;
    }
    .center {
      width: 402px;
      overflow: hidden;
      border-right: 1px solid #e5e5e5;
    }
    div {
      .sign {
        margin-left: 6px;
        padding: 2px 9px;
        border-radius: 2px;
        color: #10a050;
        border: 1px solid #10a050;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
      }
    }
    div:last-child {
      text-align: center;
      width: 18%;
      .one {
        font-size: 16px;
        font-weight: 700;
        color: #f72;
        line-height: 1;
        padding-bottom: 8px;
      }
    }
  }
}
.swiper {
  width: 100%;
}
.main {
  margin: 20px auto;
  ul {
    font-size: 14px;
    border: 1px solid #e5e5e5;
    &.h562 {
      height: 562px;
      overflow: hidden;
    }
    li {
      display: flex;
      justify-content: space-between;
      height: 24px;
      line-height: 24px;
      padding: 5px;
      overflow: hidden;
      .time {
        width: 50px;
      }
      &.title {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0,
          rgba(238, 238, 238, 1) 90%
        );
        color: #3083eb;
        padding: 7px 7px 7px 10px;
        border-bottom: 1px solid #e5e5e5;
        z-index: 99;
        position: relative;
        .h2 {
          font-size: 15px;
          font-weight: 700;
          cursor: pointer;
        }
        .fs13 {
          font-size: 13px;
          cursor: pointer;
        }
      }
    }

    &.vip {
      margin-top: 20px;

      .title {
        position: relative;
        top: -14px;
        height: 34px;
        background: url(~@/assets/icon/yc_biaoati.png) center center no-repeat;
        background-position: 50% 50%;
        background-size: 178px 34px;
        line-height: 34px;
        text-align: center;
        color: #e53e30;
        font-size: 16px;
        font-weight: 700;
        z-index: 10;
        div {
          margin-left: 37%;
        }
      }
      li {
        justify-content: left;
        router-link {
          width: 250px;
        }
      }
      .vip-top {
        display: flex;
        justify-content: left;
        height: 48px;
        background-color: #f6f6f6;
        border-left: 1px solid #e5e5e5;
        outline: #e5e5e5 dashed 1px;
        margin-top: -14px;
        padding-left: 40px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        .logo {
          color: #e35e52;
          font-weight: 700;
          width: 45px;
          height: 45px;
          line-height: 1.5;
          font-size: 16px;
          border: 1px solid #e35e52;
          background: #fff6f6;
          text-align: center;
        }
        .right {
          margin-left: 15px;
          div {
            margin-top: 5px;
            router-link {
              color: #f60;
              font-size: 13px;
            }
            &:first-child {
              font-size: 14px;
              width: 196px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 700;
              margin-top: -4px;
              router-link {
                color: #333;
              }
            }
          }
        }
      }
    }
    li {
      router-link {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .back {
    height: 835px;
    overflow: hidden;
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>