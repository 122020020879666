<template>
  <div>
    <!-- 全局的三级菜单 -->
    <typeNav />
    <NavBar />
    <Main />
    <Menu />
    <Floor :tags="tags" :tags2="tags2" />
    <Floor title="项目中心" :tags="tags3" :tags2="tags4" />
    <!--  -->
  </div>
</template>

<script>
//非路由组件在使用的时候分为三大步：定义、注册、使用
import NavBar from "./NavBar";
import Main from "./Main";
import Menu from "./Menu";
import Floor from "./Floor";
import ajax from "@/api/ajax";

export default {
  name: "home",
  components: {
    NavBar,
    Main,
    Menu,
    Floor,
  },
  data() {
    return {
      tags: [
        {
          id: 1,
          title: "公开招标",
        },
        {
          id: 2,
          title: "询价公告",
        },
        {
          id: 4,
          title: "单一来源",
        },
        {
          id: 5,
          title: "资格预审",
        },
      ],
      tags2: [
        {
          id: 7,
          title: "中标公告",
        },
        {
          id: 6,
          title: "邀请公告",
        },
        {
          id: 8,
          title: "更正公告",
        },
        {
          id: 14,
          title: "采购信息",
        },
      ],
      tags3: [
        {
          id: 3,
          title: "竞争性谈判",
        },
      ],
      tags4: [
        {
          id: 10,
          title: "竞争性磋商",
        },
      ],
    };
  },
  methods: {
    getData() {
      ajax
        .get("/article/label/view/navi")
        .then((response) => {
          this.menu = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
