import { refreshToken } from '@/utils/saveToken'
import axios from 'axios';
import nProgress from "nprogress";
// 配置不显示右上角的旋转进度条, 只显示水平进度条
nProgress.configure({ showSpinner: false })
const service = axios.create({
  timeout: 15000 //请求超时时间
})
//添加请求拦截器
service.interceptors.request.use(
  config => {
    // 显示请求中的水平进度条
    nProgress.start()
    //获取token
    const token = window.sessionStorage.getItem('dt_client_access_token');
    if (token) {
      //判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//添加响应拦截器
service.interceptors.response.use(
  response => {
    // 隐藏进度条
    nProgress.done()
    if (response.status) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response.data);
    }
  },
  error => {
    // 隐藏进度条
    nProgress.done()
    if (error.response && error.response.status === 401 && error.response.headers["token-expired"]) {
      if (parent.Vue) {
        return window.parent.refreshToken(service, error.response);
      } else {
        return refreshToken(service, error.response.data);
      }
    }
    return Promise.reject(error.response.data);
  }
);
export default service