<template>
  <div class="wrap">
    <div class="ntitle">
      <div class="h3">招标导航</div>
      <router-link :to="'/search'" class="more">
        <span>更多</span>
        <span class="icon iconfont icon-right-arrow"></span>
      </router-link>
    </div>
    <div class="wrapper d-flex">
      <el-scrollbar class="left">
        <div class="left_tabs" v-for="(item, index) in menu" :key="index">
          <h5 class="left_tabs_label">{{ item.title }}</h5>
          <ul class="tabs_box">
            <li
              v-for="(tab, i) in item.children"
              :key="tab.title + i"
              @mouseenter="handleActive(tab.title)"
              :class="[
                'tabs_item',
                { tabs_item_active: currentIndex === tab.title },
              ]"
            >
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </el-scrollbar>
      <div class="right" v-loading="loading">
        <el-row class="h-2" v-if="tags.length !== 0">
          <el-col :span="12" v-for="(item, index) in tags" :key="index">
            <div class="d-flex">
              <div
                @click="goToUrl(item.callIndex)"
                class="title ellipsis-two"
                v-html="highlightText(item.projectName)"
              ></div>
              <div class="icon" @click="handleClick(item)">
                <i class="iconfont icon-shoucang1" v-if="item.isCollec"></i>
                <i class="iconfont icon-shoucang" v-else></i>
              </div>
            </div>
            <div class="d-flex">
              <!-- <p class="tag type">{{ item.type }}</p> -->
              <p class="tag location">{{ item.areaCode }}</p>
              <p class="tag purchase">{{ item.areaName }}</p>
              <p class="tag price">
                {{ (item.projectPrice / 10000).toFixed(2) }}万
              </p>
              <p class="tag time">
                {{ formatDate(item.announcementTime) }}
              </p>
            </div>
          </el-col>
        </el-row>
        <el-empty
          v-else
          description="很抱歉，这个搜索条件没有你想要的项目"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from "@/api/ajax";
import dayjs from "dayjs";
import { debounce } from "lodash";
export default {
  data() {
    return {
      menu: [],
      show: false,
      currentIndex: "",
      collec: true,
      tags: [],
      loading: false,
    };
  },
  created() {
    this.getData();
    this.handleActive();
  },
  methods: {
    goToUrl(callIndex) {
      let routeData = this.$router.resolve({
        name: "detail",
        params: {
          id: callIndex,
        },
      });
      window.open(routeData.href, "_blank");
    },
    highlightText(text) {
      const regex = new RegExp(`(${this.currentIndex})`, "g");
      const replacedText = text.replace(
        regex,
        `<span style="color: red;font-weight: 600;">${this.currentIndex}</span>`
      );
      return replacedText;
    },
    formatDate(time) {
      return dayjs(time).format("YYYY-MM-DD");
    },
    getData() {
      ajax
        .get("/article/label/view/navi")
        .then((response) => {
          this.menu = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // 收藏
    handleClick(item) {
      const token = window.sessionStorage.getItem("dt_client_access_token");
      if (!token) {
        this.$router.push("/login");
        return;
      }
      const url = `/article/collect/`;
      ajax.put(url + item.callIndex).then((res) => {
        item.isCollec = !item.isCollec;
      });
    },
    handleScroll() {
      this.show = true;
    },
    hiddenScoll() {
      this.show = false;
    },
    handleActive: debounce(function (index) {
      this.loading = true;
      this.currentIndex = index;
      let url = index
        ? `/channel/article/view/2/10?keyword=${index}`
        : `/channel/article/view/2/10`;
      ajax
        .get(url)
        .then((response) => {
          this.loading = false;
          this.tags = response.data.map((item) => {
            return {
              ...item,
              isCollec: false,
            };
          });
          console.log("this.tags:", this.tags);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 300),
  },
};
</script>

<style lang="less" scoped>
.no-data {
  width: 1000px;
  text-align: center;
  img {
    width: 50%;
  }
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.wrapper {
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 650px;
  .left {
    width: 200px;
    height: 100%;
    overflow-x: hidden;
    background-color: #f3f7fb;
    &::-webkit-scrollbar {
      height: 0px; /* 设置滚动条的高度 */
    }
    .left_tabs {
      padding: 10px 0 0 16px;
      color: #333;
      font-size: 16px;
    }
    .left_tabs_label {
      font-weight: 700;
      line-height: 22px;
    }
    .tabs_item {
      height: 30px;
      padding-left: 25px;
      line-height: 30px;
      cursor: pointer;
      &.tabs_item_active {
        color: #fff;
        background: #3565ff;
      }
    }
  }

  .right {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .h-2 {
      width: 1000px;
      position: relative;
      cursor: pointer;
      height: 130px;
      .el-col {
        padding: 24px 20px 22px 22px;
        height: 100%;
        transition: 0.5s all;
        &:hover {
          transform: scale(1.01);
          background: #f3f7fb;
          box-shadow: -1px 1px 1px rgba(255, 255, 255, 0.4);
        }
      }
      .ellipsis-two {
        width: 70%; /* 设置容器的宽度 */
        overflow: hidden; /* 隐藏溢出内容 */
        text-overflow: ellipsis; /* 显示省略号 */
        display: -webkit-box; /* 为了兼容更多浏览器 */
        -webkit-box-orient: vertical; /* 设置为垂直方向 */
        -webkit-line-clamp: 2; /* 设置最大显示行数为2 */
      }
      .title {
        font-size: 15px;
        height: 48px;
        line-height: 24px;
        color: #444;
        padding-right: 60px;
        margin-bottom: 12px;
      }
      .icon {
        position: relative;
        right: 10px;
        i {
          font-size: 28px;
          &.icon-shoucang1 {
            color: hsl(45.07deg 100% 55.88%);
          }
        }
      }
      .tag {
        font-size: 12px;
        margin-right: 8px;
        width: 20%;
        height: 24px;
        line-height: 24px;
        color: #fff;
        border-radius: 2px;
        padding: 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80px;
        &.type {
          background: rgb(96, 136, 255);
        }
        &.location {
          color: #4c79ff;
          background: #f0f3ff;
          max-width: 80px;
        }
        &.price {
          color: #ff643d;
          background: #fff3f0;
        }
        &.purchase {
          color: #f69524;
          background: #fff5e8;
        }
        &.time {
          min-width: 120px;
          font-size: 14px;
          color: #888;
        }
      }
    }
  }
}
</style>