<template>
  <div class="warp">
    <div class="account-box">
      <div class="left-box">
        <div class="head-box">
          <div class="img-box">
            <img
              v-if="account && account.avatar"
              :src="common.loadFile(account.avatar)"
            />
            <span v-else class="icon iconfont icon-user-full"></span>
          </div>
          <div class="info">
            <router-link to="/account/password" class="text"
              >修改密码</router-link
            >
            <span class="text" @click="logout">退出登录</span>
          </div>
        </div>
        <div class="menu-box">
          <router-link
            @click.native="handleActive(index)"
            :to="menu.to"
            class="item-box"
            v-for="(menu, index) in menus"
            :key="menu.text"
            :class="{ active: active == index }"
          >
            <span class="icon iconfont" :class="menu.icon"></span>
            <span>{{ menu.text }}</span>
          </router-link>
        </div>
      </div>
      <div class="right-box">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: -1,
      account: {},
      menus: [
        {
          to: "/account/order",
          index: 0,
          icon: "icon-order",
          text: "我的订单",
        },
        {
          to: "/account/address",
          index: 1,
          icon: "icon-address",
          text: "浏览历史",
        },
        {
          to: "/account/favorite",
          index: 2,
          icon: "icon-like",
          text: "我的收藏",
        },
        {
          to: "/account/coupon",
          index: 3,
          icon: "icon-ticket",
          text: "我的优惠券",
        },
        {
          to: "/account/contribute",
          index: 4,
          icon: "icon-edit",
          text: "我的投稿",
        },
        {
          to: "/account/balance",
          index: 5,
          icon: "icon-amount",
          text: "我的余额",
        },
        {
          to: "/account/setting",
          index: 6,
          icon: "icon-info",
          text: "账户管理",
        },
      ],
    };
  },
  methods: {
    handleActive(index) {
      this.active = index;
    },
    logout() {
      window.sessionStorage.removeItem("dt_client_access_token");
      window.sessionStorage.removeItem("dt_client_refresh_token");
      //删除Cookies
      let expires = new Date();
      expires.setTime(expires.getTime() + -1 * 24 * 60 * 60 * 1000);
      document.cookie =
        "dt_client_access_token='';expires=" + expires + ";path=/";
      //跳转到登录页面
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" >
.account-box .right-box {
  width: 956px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}
.account-box .right-box .tab-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px 0 30px;
  border-bottom: 1px solid #ededed;
}
.account-box .right-box .tab-box .menu-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.account-box .right-box .tab-box .menu-box .item-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 30px;
  height: 50px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}
.account-box .right-box .tab-box .menu-box .item-box:first-child::before {
  display: none;
}
.account-box .right-box .tab-box .menu-box .item-box::before {
  content: "";
  position: absolute;
  top: 18px;
  bottom: 18px;
  left: 0;
  width: 1px;
  border-left: 1px solid #d9d9d9;
}
.account-box .right-box .tab-box .menu-box .item-box:hover {
  color: #3083eb;
}
.account-box .right-box .tab-box .menu-box .item-box.active {
  color: #3083eb;
  font-weight: 600;
}
.account-box .right-box .tab-box .menu-box .item-box.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #3083eb;
}
.account-box .right-box .tab-box .btn-box .el-link {
  margin-right: 20px;
}
.account-box .right-box .content {
  position: relative;
  padding: 30px;
  overflow: hidden;
}
.account-box .right-box .content .el-form,
.account-box .right-box .content .el-table {
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
}
.account-box .right-box .content .el-form {
  padding: 20px;
}
.account-box .right-box .content .nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  color: #999;
  font-size: 14px;
}
.account-box .right-box .content .nodata .icon {
  margin-bottom: 20px;
  color: #ebebeb;
  font-size: 54px;
  line-height: 1;
}
</style>


<style lang="less" scoped>
.account-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.account-box .left-box {
  width: 224px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}
.account-box .left-box .head-box {
  text-align: center;
  border-bottom: 20px solid #f7f7f7;
}
.account-box .left-box .head-box .img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.06);
  background: #f3f3f3;
  overflow: hidden;
}
.account-box .left-box .head-box .img-box .icon {
  color: #fff;
  font-size: 48px;
}
.account-box .left-box .head-box .img-box > img,
.account-box .left-box .head-box .img-box .el-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.account-box .left-box .head-box .name {
  margin: 0 20px;
  color: #323233;
  font-size: 17px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.account-box .left-box .head-box .info {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  padding: 10px 0;
  background: #3083eb;
}
.account-box .left-box .head-box .info .text {
  position: relative;
  margin: 0 1%;
  padding: 6px 12px;
  width: 40%;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
}
.account-box .left-box .head-box .info .text::before {
  margin-right: 3px;
}
.account-box .left-box .head-box .info .text::after {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 0;
  width: 1px;
  border-left: 1px solid #d9d9d9;
}
.account-box .left-box .head-box .info .text:first-child::after {
  display: none;
}
.account-box .left-box .menu-box {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.account-box .left-box .menu-box .item-box {
  position: relative;
  margin: 10px 0;
  padding: 10px 30px;
  color: #666;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.account-box .left-box .menu-box .item-box:hover {
  color: #3083eb;
}
.account-box .left-box .menu-box .item-box.active {
  color: #3083eb;
}
.account-box .left-box .menu-box .item-box.active::before {
  content: "";
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: 0;
  width: 2px;
  background: #3083eb;
}
.account-box .left-box .menu-box .item-box .icon {
  font-size: 15px;
}
.account-box .left-box .menu-box .item-box span:last-child {
  margin-left: 5px;
}
</style>