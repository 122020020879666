<template>
  <!--导航菜单开始 -->
  <div class="nav warp">
    <div class="nav-top">
      <ul class="menu-list">
        <li v-for="item in menu" :key="item.name">
          <img :src="item.imgSrc" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="nav-bottom">
      <div class="item" @click="handleClick">
        <ul>
          <li>
            <router-link class="ff6420" to="/">招标预告</router-link>
          </li>
          <li>
            <router-link to="/" class="zjxm">招标公告</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">招标变更</router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/">中标结果</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">免费招标</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">招标跟踪</router-link>
          </li>
        </ul>
      </div>
      <div class="item" @click="handleClick">
        <ul>
          <li>
            <router-link class="ff6420" to="/">拟在建设项目</router-link>
          </li>
          <li>
            <router-link to="/" class="zjxm">精选项目</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">VIP项目</router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link class="ff6420" to="/">政府项目 </router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">商业项目</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">招标跟踪</router-link>
          </li>
        </ul>
      </div>
      <div class="item" @click="handleClick">
        <ul>
          <li>
            <router-link class="ff6420" to="/">招标代理机构</router-link>
          </li>
          <li>
            <router-link to="/" class="zjxm">工程交易中心</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">政府采购中心</router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/">设计单位</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">业主单位</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">企业名录</router-link>
          </li>
        </ul>
      </div>
      <div class="item" @click="handleClick">
        <ul>
          <li>
            <router-link class="ff6420" to="/">发布招标</router-link>
          </li>
          <li>
            <router-link to="/" class="zjxm">邀请招标</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">推荐招标</router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/">数据商城</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">企业名录</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">企业监控</router-link>
          </li>
        </ul>
      </div>
      <div class="item" @click="handleClick">
        <ul>
          <li>
            <router-link class="ff6420" to="/">推荐业主</router-link>
          </li>
          <li>
            <router-link to="/" class="zjxm">发布招标</router-link>
          </li>
          <li>
            <router-link class="ff6420" to="/">招标跟踪</router-link>
          </li>
        </ul>
        <ul>
          <li>
            <router-link to="/">谈判采购</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">成功案例</router-link>
          </li>
          <li>
            <router-link class="zjxm" to="/">服务列表</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- 导航菜单结束 -->
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          name: "招标",
          imgSrc: require("@/assets/icon/dh_01.png"),
        },
        {
          name: "项目",
          imgSrc: require("@/assets/icon/dh_02.png"),
        },
        {
          name: "机构",
          imgSrc: require("@/assets/icon/dh_03.png"),
        },
        {
          name: "服务",
          imgSrc: require("@/assets/icon/dh_05.png"),
        },
      ],
      tags: [
        [
          {
            text: "招标预告",
            isHot: true,
          },
          {
            text: "招标公告",
            isNew: true,
          },
          {
            text: "招标变更",
          },
          {
            text: "中标结果",
            isNew: true,
          },
          {
            text: "免费招标",
          },
          {
            text: "招标跟踪",
          },
        ],
        [],
        [],
        [],
      ],
    };
  },
  methods: {
    // 回传到搜索栏
    handleClick(event) {
      let tagName = event.target.tagName.toLowerCase();
      if (tagName === "a") {
        const keyword = event.target.textContent;
        let routeData = this.$router.resolve({
          name: "search",
          params: {
            keyword,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  width: 1200px;
  .nav-top {
    height: 40px;
    background: #3083eb;
    .menu-list {
      color: #fff;
      display: flex;
      height: 100%;
      line-height: 40px;
      font-size: 16px;
      li {
        width: 20%;
        text-align: center;
        position: relative;
        &::before {
          position: absolute;
          top: 5px;
          right: 0;
          display: inline-block;
          content: "";
          height: 30px;
          width: 1px;
          background: rgba(141, 137, 137, 0.7);
        }
        img {
          position: relative;
          top: 3px;
          margin-right: 5px;
        }
      }
    }
  }
  .nav-bottom {
    height: 100px;
    display: flex;
    width: 100%;
    overflow: hidden;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    .item {
      padding: 10px 0;
      font-size: 14px;
      width: 20%;
      display: flex;
      justify-content: space-around;
      position: relative;
      &:last-child::before {
        display: none;
      }
      &::before {
        position: absolute;
        top: 5px;
        right: 1px;
        display: inline-block;
        content: "";
        height: 87px;
        width: 1px;
        background: #e5e5e5;
      }
      ul > li {
        margin-bottom: 10px;
        a.ff6420 {
          color: #ff6420;
          font-weight: 700;
        }
        a.zjxm {
          color: #333;
        }
      }
    }
  }
}
</style>
