<template>
  <div>
    <typeNav />
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link to="/search">招标信息</router-link>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!--查询条件.开始-->
      <div class="goods-screen">
        <div class="list-box" id="box-1">
          <span class="title">地区范围</span>
          <div class="item-box" id="item-box">
            <span
              @click="handleCategoryChange({ id: 0 })"
              :class="{ active: categoryId === 0 }"
              class="text"
              >全部</span
            >
            <span
              v-for="(item, index) in categoryList"
              :key="index"
              @click="handleCategoryChange(item)"
              :class="{ active: categoryId === item.id }"
              class="text"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="item-box-2" v-show="cityShow">
          <span class="title">市级：</span>
          <div class="item-box">
            <span
              @click="handleCityChange(0)"
              :class="{ city_active: cityId === 0 }"
              class="text"
              >不限</span
            >
            <span
              v-for="(item, index) in cityList"
              :key="index"
              @click="handleCityChange(item.id)"
              :class="{ city_active: cityId === item.id }"
              class="text"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="list-box">
          <span class="title">发布时间</span>
          <div class="item-box">
            <span
              @click="handleBrandChange(0)"
              :class="{ active: brandId === 0 }"
              class="text"
              >全部</span
            >
            <span
              v-for="(item, index) in brandList"
              :key="index"
              @click="handleBrandChange(item.id)"
              :class="{ active: brandId === item.id }"
              class="text"
              >{{ item.title }}</span
            >
          </div>
          <div class="item-box">
            <el-date-picker
              size="mini"
              v-model="value1"
              @change="handleDateChange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="list-box">
          <span class="title">信息类型</span>
          <div class="item-box">
            <span
              @click="handleLabelChange(0)"
              :class="{ active: labelId === 0 }"
              class="text"
              >全部</span
            >
            <span
              v-for="(item, index) in labelList"
              :key="index"
              @click="handleLabelChange(item.id)"
              :class="{ active: labelId === item.id }"
              class="text"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="list-box">
          <span class="title">项目金额</span>
          <div class="item-box">
            <span
              @click="handleMoneyChange(0)"
              :class="{ active: moneyId === 0 }"
              class="text"
              >全部</span
            >
            <span
              v-for="(item, index) in moneyList"
              :key="index"
              @click="handleMoneyChange(item.id)"
              :class="{ active: moneyId === item.id }"
              class="text"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="list-box" style="margin-left: 80px">
          <div class="item-box">
            <div class="input">
              <el-input v-model="minPrice" size="mini" clearable>
                <template slot="prepend">￥</template>
              </el-input>
              万
            </div>
            <span class="line">-</span>
            <div class="input">
              <el-input v-model="maxPrice" size="mini" clearable>
                <template slot="prepend">￥</template>
              </el-input>
              万
            </div>
            <div class="btn">
              <el-button @click="handlePriceChange" size="mini">确认</el-button>
            </div>
          </div>
        </div>
      </div>
      <!--查询条件.结束-->
      <!--商品列表.开始-->
      <div class="content-box">
        <div class="box-800">
          <div class="warp">
            <div>
              <!-- 标题开始 -->
              <ul class="head title">
                <li>标题</li>
                <li>类型</li>
                <li>地区</li>
                <li>时间</li>
              </ul>
              <!-- 标题结束 -->
              <!-- 内容开始 -->
              <div v-loading="loading">
                <ul class="content" v-for="item in list" :key="item.callIndex">
                  <li>
                    <div
                      class="list-top"
                      v-html="highlightText(item.title)"
                      @click="goToUrl(item)"
                    ></div>
                    <div class="list-bottom">
                      <p>
                        <i class="el-icon-platform-eleme"></i>
                        招标单位： {{ item.buyerAgent }}
                        <!-- <el-button type="warning" plain size="mini"
                          >企业情报报告下载</el-button
                        > -->
                      </p>
                      <p>
                        <i class="el-icon-platform-eleme"></i>
                        项目金额：{{
                          item.projectPrice
                            ? parseInt(item.projectPrice / 10000)
                            : "***"
                        }}万 明细请联系客服
                      </p>
                    </div>
                  </li>
                  <li>
                    <el-tag type="success" size="mini"
                      >招标- {{ item.categoryTitle }}</el-tag
                    >
                  </li>
                  <li>
                    <i
                      class="el-icon-location-outline p-r-3"
                      style="color: #3083eb; font-size: 20px"
                    ></i>
                    {{ item.areaName }}
                  </li>
                  <li style="text-align: end; padding-top: 3px">
                    <div>{{ formatDate(item.announcementTime) }}</div>
                    <div class="collect" @click="handleChange(item)">
                      <i
                        class="p-r-1"
                        :class="
                          item.status == 1
                            ? 'el-icon-star-on'
                            : 'el-icon-star-off'
                        "
                        style="font-size: 22px"
                        :style="{ color: item.status == 1 ? 'orange' : '' }"
                      ></i>
                      收藏
                    </div>
                  </li>
                </ul>
              </div>
              <!-- 内容结束 -->
            </div>
          </div>
        </div>
        <div class="box-380">
          <div class="slider-box">
            <div class="ftitle">
              <span class="h5">热门项目</span>
              <div class="more link">
                <span class="icon iconfont icon-right-arrow"></span>
              </div>
            </div>
            <div class="news-hot">
              <router-link
                style="color: #333; text-decoration: none"
                :to="'/detail/' + item.callIndex"
                v-for="(item, index) in hotList"
                :key="index"
                class="list-box"
              >
                <div class="view iconfont icon-view">
                  {{ item.projectName }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!--商品列表.结束-->
      <!--分页器.开始-->
      <div class="pager-box">
        <el-pagination
          background
          :hide-on-single-page="false"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
      <!--分页器.结束-->
    </div>
  </div>
</template>

<script>
import ajax from "@/api/ajax";
import dayjs from "dayjs";
export default {
  name: "Search",
  data() {
    return {
      // 市级单位的显示和隐藏
      cityShow: false,
      loading: false,
      list: [],
      cityList: [],
      hotList: [],
      collect: false,
      searchText:
        "20230616北电设备（电力...在正文或附件中）公司WJ22303006等维检事业部磨煤机备件招标", // 原始文本
      value1: "",
      areaCode: "", //区号
      moneyId: 0, //排序索引
      priceOrder: 0, //1价格从低到高 2价格从高到低
      labelId: 0, //标签ID
      brandId: 0, //发布时间
      categoryId: 0, //分类ID
      cityId: 0,
      minPrice: "", //最低价格
      maxPrice: "", //最高价格
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      StartDate: dayjs().subtract(1, "years").format("YYYY-MM-DD"),
      categoryList: [], //分类列表
      moneyList: [
        {
          id: 1,
          title: "10万以内",
        },
        {
          id: 2,
          title: "10万-50万",
        },
        {
          id: 3,
          title: "50万-100万",
        },
        {
          id: 4,
          title: "100万-500万",
        },
        {
          id: 5,
          title: "500万-1000万",
        },
        {
          id: 6,
          title: "1000万-5000万",
        },
        {
          id: 7,
          title: "5000万以上",
        },
      ],
      labelList: [
        {
          id: 1,
          title: "公开招标",
        },
        {
          id: 2,
          title: "询价公告",
        },
        {
          id: 10,
          title: "竞争性磋商",
        },
        {
          id: 5,
          title: "资格预审",
        },
        {
          id: 8,
          title: "更正公告",
        },
      ], //信息类型列表
      brandList: [
        {
          id: 1,
          title: "近一周",
        },
        {
          id: 2,
          title: "近一月",
        },
        {
          id: 3,
          title: "近三月",
        },
        {
          id: 4,
          title: "近半年",
        },
        {
          id: 5,
          title: "近一年",
        },
      ], //发布时间
      listData: [], //数据列表
    };
  },
  computed: {},
  watch: {
    "$store.state.keyword"(newValue, oldValue) {
      this.loadData();
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    highlightText(searchText) {
      const regex = new RegExp(`(${this.$store.state.keyword})`, "g");
      const replacedText = searchText.replace(
        regex,
        `<span style="color: red;">${this.$store.state.keyword}</span>`
      );
      return replacedText;
    },
    getNews() {
      const StartDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
      const EndDate = dayjs().subtract(5, "day").format("YYYY-MM-DD");
      let params = {
        StartDate,
        EndDate,
        SiteId: "1",
      };
      ajax.get("/news/article", { params }).then((response) => {
        this.hotList = response.data;
      });
    },
    goToUrl(item) {
      this.$router.push("/detail/" + item.callIndex);
    },
    handleChange(item) {
      console.log("item:", item);
      const url = `/article/collect/`;
      ajax.put(url + item.callIndex).then((res) => {
        item.status = 1;
      });
    },
    //加载商品列表
    loadData() {
      this.loading = true;
      const params = {
        CategoryId: this.labelId,
        StartDate: this.StartDate,
        EndDate: dayjs().format("YYYY-MM-DD"),
        AreaCode: this.areaCode,
        MinProjectPrice: this.minPrice,
        MaxProjectPrice: this.maxPrice,
        SiteId: 1,
        Keyword: this.$store.state.keyword || "项目",
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      ajax
        .get("/news/article", { params })
        .then((response) => {
          let pagination = JSON.parse(response.headers["x-pagination"]);
          this.list = response.data;
          this.totalCount = pagination.totalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //筛选省级单位
    handleCategoryChange(item) {
      this.areaCode = item.code || undefined;
      this.cityShow = true;
      ajax.get("/area/" + item.id).then((response) => {
        console.log(response.data);
        if (item.id !== 0) {
          this.cityShow = true;
          this.cityList = response.data;
        } else {
          this.cityShow = false;
          this.categoryList = response.data;
        }
      });
      this.categoryId = item.id;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //筛选市级单位
    handleCityChange(val) {
      this.cityId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //发布时间
    handleBrandChange(val) {
      this.brandId = val;
      switch (val) {
        case 0:
          this.StartDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
          break;
        case 1:
          this.StartDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
          break;
        case 2:
          this.StartDate = dayjs().subtract(1, "month").format("YYYY-MM-DD");
          break;

        case 3:
          this.StartDate = dayjs().subtract(3, "month").format("YYYY-MM-DD");
          break;
        case 4:
          this.StartDate = dayjs().subtract(6, "month").format("YYYY-MM-DD");
          break;
        case 5:
          this.StartDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");
          break;
      }
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //筛选标签
    handleLabelChange(val) {
      this.labelId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //筛选项目金额
    handleMoneyChange(val) {
      switch (val) {
        case 0:
          this.minPrice = "";
          this.maxPrice = "";
          break;
        case 1:
          this.maxPrice = 100000;
          break;
        case 2:
          this.minPrice = 100000;
          this.maxPrice = 500000;
          break;

        case 3:
          this.minPrice = 500000;
          this.maxPrice = 1000000;
          break;
        case 4:
          this.minPrice = 1000000;
          this.maxPrice = 5000000;
          break;
        case 5:
          this.minPrice = 5000000;
          this.maxPrice = 10000000;
          break;
        case 6:
          this.minPrice = 10000000;
          this.maxPrice = 50000000;
          break;
        case 7:
          this.minPrice = 50000000;
          this.maxPrice = "";
          break;
      }
      this.moneyId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    handleDateChange(e) {
      this.StartDate = dayjs(e[0]).format("YYYY-MM-DD");
      this.EndDate = dayjs(e[1]).format("YYYY-MM-DD");
      this.loadData();
    },
    //确认
    handlePriceChange() {
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
  },
  created() {
    this.getNews();
    this.loadData();
    this.handleCategoryChange({ id: 0 });
    // this.common.param.keyword = this.common.queryValue("keyword");
  },
};
</script>
<style lang="less" scoped>
#box-1 {
  width: 1131px;
  overflow-x: auto;
  #item-box {
    display: block;
    overflow: auto;
  }
}
.txt-box {
  display: flex;
}
// 新加样式
.slider-box {
  margin-top: 20px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  .ftitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ededed;
    box-sizing: border-box;
  }
  .ftitle .h5 {
    color: #333;
    font-size: 18px;
    font-weight: 500;
  }
  .ftitle .more {
    width: 18px;
    height: 18px;
    color: #818181;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
  }
  .ftitle .more .icon {
    display: block;
    font-size: 12px;
    line-height: 18px;
  }
}

.content-box {
  display: flex;
  justify-content: space-between;
}
.content-box .box-800 {
  width: 800px;
}
.content-box .box-380 {
  width: 380px;
}
.p-r-3 {
  position: relative;
  top: 3px;
}
.p-r-1 {
  position: relative;
  top: 2px;
}
.item-box-2 {
  display: flex;
  background-color: #f6f6f6;
  line-height: 40px;
  margin-left: 75px;
  padding: 0 10px;
}

.item-box-2 .title {
  color: #3083eb;
  min-width: 45px;
}

.item-box-2 .text {
  margin-left: 20px;
  padding: 5px 10px;
  line-height: 1;
  border-radius: 3px;
  cursor: pointer;
}

.item-box-2 .text.city_active {
  color: #fff;
  background: #3083eb;
}
.warp {
  width: 100%;
  margin-top: 20px;
  .head.title {
    width: 100%;
    display: flex;
    overflow: hidden;
    background: #fafafa;
    border: 1px solid #eeeeee;
    li {
      width: 12%;
      height: 40px;
      overflow: hidden;
      line-height: 14px;
      padding: 13px 0 13px 30px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: left;
      &:first-child {
        width: 74%;
      }
    }
  }
  ul.content {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 28px;
    border-right: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    &:hover {
      transform: scale(1);
      box-shadow: 0px 1px 7.2px 2.8px rgba(201, 201, 201, 0.34);
    }
    li {
      width: 12%;
      font-size: 14px;
      text-align: center;
      position: relative;
      overflow: hidden;
      margin: 10px 0;
      .collect {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: 16px;
      }
      &:first-child {
        width: 64%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        line-height: 0;
        .list-top {
          vertical-align: middle;
          line-height: 24px;
          font-size: 18px !important;
          font-weight: bold;
          cursor: pointer;
        }
        .list-bottom {
          color: #666;
          p {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
.wrapper {
  width: 1200px;
  margin: 10px auto;
  .location {
    margin: 20px 0;
  }

  .pager-box {
    text-align: center;
    width: 800px;
  }
  .pager-box .el-pagination {
    margin-top: 20px;
  }
  .goods-screen {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0 20px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
    overflow: hidden;
  }
  .goods-screen .list-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 50px;
    border-top: 1px solid #f5f5f5;
  }
  .goods-screen .list-box:first-child(1) {
    border: none;
  }
  .goods-screen .list-box .title {
    color: #999;
    min-width: 65px;
  }
  .goods-screen .list-box .item-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .goods-screen .list-box .item-box .text {
    margin-left: 20px;
    padding: 5px 10px;
    line-height: 1;
    border-radius: 3px;
    cursor: pointer;
  }
  .goods-screen .list-box .item-box .text.active {
    color: #fff;
    background: #3083eb;
  }
  .goods-screen .list-box .item-box .text.active .icon {
    color: #3083eb;
  }
  .goods-screen .list-box .item-box .text .icon {
    font-size: 14px;
  }
  .goods-screen .list-box .item-box .input .el-input {
    width: 120px;
  }
  .goods-screen .list-box .item-box .input .el-input .el-input-group__prepend {
    padding: 0 5px;
  }
  .goods-screen .list-box .item-box .line {
    margin: 0 5px;
    color: #ccc;
  }
  .goods-screen .list-box .item-box .btn {
    margin-left: 10px;
  }
  .list-box .view {
    padding: 10px;
    white-space: nowrap; /* 阻止文本换行 */
    overflow: hidden; /* 隐藏溢出的文本 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  }
}
</style>
