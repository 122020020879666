<template>
  <div>
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>公司介绍</el-breadcrumb-item>
          <el-breadcrumb-item>联系我们</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="content-box">
        <!--内容详情.开始-->
        <div class="detail-box">
          <span class="h3">联系我们</span>
          <div class="meta">
            <span>来源:本站</span>
            <span class="icon iconfont icon-date">2023/9/1 12:17:04</span>
          </div>
          <div class="content">
            <h3 style="text-align: left">指南针招标 - 汇聚各地招标采购信息</h3>
            <p style="text-align: left">官方网址：http://www.znzzbw.com/</p>
            <p style="text-align: left">
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;http://www.znzzbw.com/
            </p>
            <p style="text-align: left">
              地 &nbsp; &nbsp; &nbsp;址：北京市怀柔区桥梓镇兴桥大街1号南楼203室
            </p>
            <p style="text-align: left">业务电话：+86 17732384333(微信同号)</p>
            <p style="text-align: left">业 务 QQ：1258237852</p>
            <p style="text-align: left">电子邮箱：1258237852@qq.com</p>
          </div>
        </div>
        <!--内容详情.结束-->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 100%;
  .location {
    margin-top: 30px;
  }
  .content-box {
    display: flex;
    justify-content: space-between;
  }
}
.detail-box {
  margin-top: 20px;
  padding: 30px 30px 20px;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}
.detail-box .h3 {
  color: #282828;
  font-size: 20px;
  line-height: 38px;
}
.detail-box .meta {
  margin-top: 10px;
  line-height: 1;
  color: #999;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.detail-box .meta span {
  margin-right: 10px;
}
.detail-box .meta .icon {
  font-size: 14px;
}
.detail-box .meta .icon::before {
  margin-right: 5px;
}
.detail-box .meta .icon.click {
  cursor: pointer;
}
.detail-box .content {
  position: relative;
  margin-top: 30px;
  color: #333;
  font-size: 15px;
  line-height: 30px;
}
.detail-box .content p {
  margin: 10px 0;
}
.detail-box .content img {
  max-width: 100%;
}
.detail-box .album-list {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin: 10px 0 0 -20px;
  overflow: hidden;
}
.detail-box .album-list .list-box {
  position: relative;
  margin: 20px 0 0 20px;
}
.detail-box .album-list .list-box .img-box {
  position: relative;
  width: 233px;
  height: 233px;
  overflow: hidden;
}
.detail-box .album-list .list-box .img-box > img,
.detail-box .album-list .list-box .img-box .el-image {
  width: 100%;
  height: 100%;
}
.detail-box .album-list .list-box .txt-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 36px;
  background: rgba(0, 0, 0, 0.1);
}
.detail-box .album-list .list-box .txt-box .text {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.detail-box .attach-list {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
}
.detail-box .attach-list .list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.02);
}
.detail-box .attach-list .list-box .left-box {
  display: flex;
  justify-content: flex-start;
}
.detail-box .attach-list .list-box .left-box .icon {
  margin-right: 20px;
  color: #ccc;
  font-size: 40px;
}
.detail-box .attach-list .list-box .left-box .txt-box .title {
  display: block;
  color: #282828;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.detail-box .attach-list .list-box .left-box .txt-box .info {
  display: block;
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  line-height: 1;
}
</style>